import { createContext } from 'react';

export interface FeatureFlagsContextValue {
    TB_SCREENING?: boolean;
    RATE_CALCULATOR?: boolean;
}

export const FeatureFlagsContext = createContext<FeatureFlagsContextValue>(
    undefined as any
);
